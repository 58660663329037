import { useContext, useEffect, useState } from "react";
import { CustomModal } from "../../../stories/Modal/CustomModal";
import { Button } from "../../../stories/Button/Button";
import Countdown from "antd/es/statistic/Countdown";
import { PiWarningFill } from "react-icons/pi";
import { IoCloseCircle } from "react-icons/io5";
import { Colors } from "../../ThemeConfig";
import { ActionTypes, UserContext } from "../../../contexts/userProvider";
import { useNavigate } from "react-router-dom";
import { RenewToken } from "../../../utils/requests/auth";
import React from "react";
import moment from "moment";
import { SaveLogSession } from "../../../utils/requests/logConnexion";

export const TokenRefresher: React.FC<{}> = (props) => {
    const { userStore, dispatch } = useContext(UserContext);
    const { userInfos } = userStore;
    const userRole = userInfos?.role?.name;
    const [isDisconnected, setIsDisconnected] = useState(false);
    const [isRefreshingToken, setRefreshingToken] = useState(false);
    const nav = useNavigate();
    const SESSION_END_MODAL_DURATION = parseInt(process.env.REACT_APP_SESSION_END_MODAL_DURATION || "2", 10);
    const [deadline, setDeadline] = useState(Date.now() + SESSION_END_MODAL_DURATION * 60000);
    
    useEffect(() => {
        if (userInfos?.isOnline) {
            // Récupérération de l'heure de début de sa session
            const log_connexions = userInfos?.log_connexions;
            const sessionStart = new Date(log_connexions[log_connexions?.length - 1].startDate).getTime();

            // Calcul de la fin de la session en millisecondes en fonction du role
            let sessionDurationInMinutes;
            switch (userRole) {
                case "Patient":
                    sessionDurationInMinutes = 60;
                    break;
                case "Medecin":
                    sessionDurationInMinutes = 180;
                    break;
                case "Admin":
                    sessionDurationInMinutes = 30;
                    break;
                case "Admin_orga":
                    sessionDurationInMinutes = 30;
                    break;
                default:
                    sessionDurationInMinutes = 60;
                    break;
            }
            const sessionEnd = sessionStart + sessionDurationInMinutes * 60 * 1000;
            setDeadline(sessionEnd - 5000); // Avancer de 5 secondes le countdown

            // Intervalle qui vérifie si on arrive à 2 minutes avant la fin de la session
            const intervalId = setInterval(() => {
                const now = Date.now();
                const timeRemaining = sessionEnd - now;
                
                // Vérifiez si le temps restant est inférieur ou égal à 2 minutes (2 * 60 * 1000 ms) + 5s
                if (timeRemaining <= (SESSION_END_MODAL_DURATION * 60 * 1000) + 5000) {
                    // On fait le dispatch pour informer dans le userStore qu'il faut afficher TokenRefresher
                    dispatch({
                        type: ActionTypes.openTokenRefresher,
                        payload: {
                            isTokenRefresherOpen: true,
                        }}
                    );
                } 
                // Si le temps est écoulé on affiche le modal "session expirée"
                // et on arrête l'interval
                if (timeRemaining <= 0){
                    setIsDisconnected(true);
                    clearInterval(intervalId);
                }
            }, 1000); // Vérifiez toutes les secondes

            // Cleanup pour nettoyer l'intervalle lorsqu'il n'est plus nécessaire
            return () => clearInterval(intervalId);
        }
    }, [SESSION_END_MODAL_DURATION, dispatch, userInfos?.isOnline, userInfos?.log_connexions, userRole, userStore.isTokenRefresherOpen]);

    return (
        <>
            {userStore.isTokenRefresherOpen && (!isDisconnected ? (
                <CustomModal 
                    open={true}
                    closable={false}
                    footer={
                        <>
                        { !isRefreshingToken ? 
                            <div className="flex justify-center gap-4 w-full">
                                <Button 
                                    type="button"
                                    format="default"
                                    color="default"
                                    className="w-full max-w-[160px] text-[13px] md:text-md py-5"
                                    onClick={() => {
                                        dispatch({ type: ActionTypes.logout });
                                    }}
                                    >
                                    <>
                                        Me déconnecter
                                    </>
                                </Button>
                                <Button 
                                    type="button"
                                    format="default"
                                    color="primary"
                                    className="w-full max-w-[200px] text-[13px] md:text-md py-5"
                                    onClick={() => {
                                        try {
                                            setRefreshingToken(true);
                                            setTimeout(() => {
                                                RenewToken().then((response) => {
                                                    const { jwt } = response?.data;
                                                    dispatch({
                                                        type: ActionTypes.authConfirm,
                                                        payload: {
                                                        jtw: jwt,
                                                        },
                                                    })
                                                    setTimeout(() => {
                                                        document.location.href = "/";
                                                    }, 400);
                                                }).catch(() => {
                                                    dispatch({
                                                    type: ActionTypes.logout,
                                                    });
                                                    setTimeout(() => {
                                                        if(userRole === "Medecin") nav("/login-ps");
                                                        nav("/login");
                                                    }, 500);
                                                });
                                            }, 500);
                                            
                                        } catch (error) {
                                            console.error(error);
                                        }
                                    }}
                                    >
                                    <>
                                        Prolonger ma session
                                    </>
                                </Button>
                            </div> : <></>
                        }
                        </>
                    
                }>
                    <div className="flex flex-col justify-center items-center text-center text--default">
                        <div className="hidden md:flex">
                            <PiWarningFill className="mb-2" color={Colors.warningDark} size={65}/>
                        </div>
                        <h4 className="text--default mb-4">
                            Votre session est sur le point d'expirer
                        </h4>
                        { !isRefreshingToken ? 
                            <div className="mb-3">
                                <Countdown 
                                    title="Vous allez être déconnecté(e) dans" 
                                    className="flex flex-col justify-center items-center"    
                                    format="mm:ss"
                                    value={deadline} 
                                    onFinish={() => {
                                        // Properly log out the user without redirecting
                                        const logConnexions = userInfos?.log_connexions
                                        const lastConnexion = logConnexions && logConnexions[logConnexions?.length - 1]
                                        try {
                                            lastConnexion && SaveLogSession({
                                                id: lastConnexion?.id,
                                                user_id: userInfos?.id,
                                                startDate: lastConnexion?.startDate,
                                                endDate: moment().format("YYYY-MM-DDTHH:mm:ss.sssZ")
                                            })
                                        } catch (error: any) {
                                            console.error(error.response?.data?.error || error?.message)
                                        }
                                        // Change modal aspect
                                        setIsDisconnected(true);
                                    }}
                                />
                            </div> : 
                            <span className="text--grey text-[15px] mb-4">Rafraichîssement de la session en cours...</span>
                        }
                    </div>
                </CustomModal>
            ) : 
            (
                <CustomModal 
                    open={true}
                    closable={false}
                    footer={
                    <div className="flex justify-center gap-4 w-full">
                        <Button 
                            type="button"
                            format="default"
                            color="primary"
                            className="w-full max-w-[300px] text-[13px] md:text-md py-5"
                            onClick={() => {
                                setTimeout(() => {
                                    if(userRole === "Medecin") nav("/login-ps");
                                    nav("/login");
                                }, 500);
                            }}
                        >
                            <>
                                Accéder à la page de connexion
                            </>
                        </Button>
                    </div>
                }>
                    <div className="flex flex-col justify-center items-center text-center text--default">
                        <div className="hidden md:flex">
                            <IoCloseCircle className="mb-2" color={Colors.dangerDark} size={65}/>
                        </div>
                        <h4 className="text--default mb-2">
                            Votre session a expiré
                        </h4>
                        <p className="text--grey text-sm mb-2">
                            Veuillez vous reconnecter pour accéder à votre espace Lianeli.
                        </p>
                    </div>
                </CustomModal>
            ))}
        </>
    );
}