/**
 * High level router.
 */

import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import SecurtityGuard from "./pages/Auth/SecurtityGuard";

const Index = lazy(() => import("./pages/Index"));
// ***************** DOCTORS RESSOURCES ************************
const DoctorDashboard = lazy(() => import('./pages/Doctors/Dashboard/Dashboard'));
const Patients = lazy(() => import('./pages/Doctors/Patients/Patients'));
const ShareRegisterLink = lazy(() => import('./pages/Doctors/Share/ShareRegisterLink'));
const DocScreeningFiles = lazy(() => import('./pages/Doctors/ScreeningFiles/ScreeningFiles'));
const DocAppointments = lazy(() => import('./pages/Doctors/Appointments/Appointments'));
const DocHelpCenter = lazy(() => import('./pages/Doctors/HelpCenter/HelpCenter'));
const DoctorNotifications = lazy(() => import('./pages/Doctors/Notifications/Notifications'));
// ***************** PATIENTS RESSOURCES ************************
const OnboardingPatient = lazy(() => import('./pages/Patients/Onboarding/Onboarding'));
const DashboardPatient = lazy(() => import('./pages/Patients/Dashboard/Dashboard'));
const QuestionsPatient = lazy(() => import('./pages/Patients/Questions/FormPatient'));
const ScreeningFiles = lazy(() => import('./pages/Patients/ScreeningFiles/ScreeningFiles'));
const HelpCenter = lazy(() => import('./pages/Patients/HelpCenter/HelpCenter'));
const ScreeningsPatient = lazy(() => import('./pages/Patients/Screenings/Screenings'));
const PlanningPatient = lazy(() => import('./pages/Patients/Planning/Planning'));
const ContactPatient = lazy(() => import('./pages/Patients/Contact/Contact'));
// ***************** ADMINS RESSOURCES ************************
const AdminNotifications = lazy(() => import('./pages/Admin/Notifications/Notifications'));
const Statistics = lazy(() => import("./pages/Admin/Statistics"));
const Organizations = lazy(() => import("./pages/Admin/Organizations"));
const DetailsOrganization = lazy(() => import("./pages/Admin/DetailsOrganization"));
const UserRequests = lazy(() => import('./pages/Admin/UserRequests'));
// ***************** ADMINS_ORGA RESSOURCES ************************
const Dashboard = lazy(() => import("./pages/Admin_orga/Dashboard"));

// *************************************************************
const Login = lazy(() => import("./pages/Auth/Login"));
const LoginDoctor = lazy(() => import("./pages/Auth/LoginDoctor"));
const SignUp = lazy(() => import("./pages/Auth/SignUp"));
const PatientSignUp = lazy(() => import("./pages/Auth/PatientSignUp"));
const DoctorSignUp = lazy(() => import("./pages/Auth/DoctorSignUp"));
const ForgotPassword = lazy(() => import("./pages/Auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/Auth/ResetPassword"));
const AuthFromPartner = lazy(() => import("./pages/Auth/AuthFromPartner"));
const EmailSettings = lazy(() => import("./pages/Auth/EmailSettings"));
const HandleDoctorAccess = lazy(() => import("./pages/Auth/HandleDoctorAccess"));
const RdvWithDoctor = lazy(() => import("./pages/Auth/RdvWithDoctor"));
const EmailConfirmation = lazy(() => import("./pages/Auth/EmailConfirmation"));
const AddPatientQRcode = lazy(() => import("./pages/Auth/AddPatientQRcode"));
const AuthConfirmation = lazy(() => import("./pages/Auth/AuthConfirmation"));
const SignupConfirmation = lazy(() => import("./pages/Auth/SignupConfirmation"));
const MainLayout = lazy(() => import("./layout/MainLayout"));
const ErrorPage = lazy(() => import("./pages/Errors/ErrorPage"));
const NotAuthorizedPage = lazy(
  () => import("./pages/Errors/NotAuthorizedPage")
);

export function BaseRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<SecurtityGuard component={<Index />} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login-ps" element={<LoginDoctor />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/auth-partner/:token" element={<AuthFromPartner />} />
        <Route path="/user-data/:uid" element={<RdvWithDoctor />} />
        <Route path="/user-settings/:uid" element={<EmailSettings />} />
        <Route path="/handle-doctor-access/:patient_uid/:doctor_uid" element={<HandleDoctorAccess />} />
        <Route path="/auth-confirmation/:role" element={<AuthConfirmation />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/signup-confirmation/:uid" element={<SignupConfirmation />} />
        <Route path="/sign-up/patient" element={<PatientSignUp />} />
        <Route path="/sign-up/medecin" element={<DoctorSignUp />} />
        <Route path="/email-confirmation" element={<EmailConfirmation />} />
        <Route path="/link-patient/:uid" element={<AddPatientQRcode />} />
        <Route path="/medecin/:uid" element={<ShareRegisterLink />} />
        <Route  
          path="/medecin"
          element={
            <SecurtityGuard component={<MainLayout granted="Medecin" />} />
          }
        >
          <Route path="rapport-patients" element={<DoctorDashboard />} />
          <Route path="patients" element={<Patients />} />
          <Route path="mes-rendez-vous" element={<DocAppointments />} />
          <Route path="fiches-des-depistages" element={<DocScreeningFiles />} />
          <Route path="centre-aide" element={<DocHelpCenter />} />
          <Route path="mes-notifications" element={<DoctorNotifications />} />
        </Route>
        <Route
          path="/patient"
          element={
            <SecurtityGuard component={<MainLayout granted="Patient" />} />
          }
        >
          <Route path="onboarding" element={<OnboardingPatient />} />
          <Route path="accueil" element={<DashboardPatient />} />
          <Route path="mon-questionnaire" element={<QuestionsPatient />} />
          <Route path="mes-depistages" element={<ScreeningsPatient />} />
          <Route path="mon-planning" element={<PlanningPatient />} />
          <Route path="fiches-des-depistages" element={<ScreeningFiles />} />
          <Route path="centre-aide" element={<HelpCenter />} />
          <Route path="contact" element={<ContactPatient />} />
        </Route>
        <Route  
          path="/admin"
          element={
            <SecurtityGuard component={<MainLayout granted="Admin" />} />
          }
        >
          <Route path="tableau-de-bord" element={<Statistics />} />
          <Route path="organisations" element={<Organizations />} />
          <Route path="demandes-utilisateurs" element={<UserRequests />} />
       
        </Route>
        <Route  
          path="/admin-orga"
          element={
            <SecurtityGuard component={<MainLayout granted="Admin_orga" />} />
          }
        >
          <Route path="tableau-de-bord" element={<Dashboard />} />       
        </Route>
        <Route path="/403" element={<NotAuthorizedPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}
